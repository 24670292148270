import React from 'react';
import './Footer.css';
import { IoLogoInstagram, IoIosMail, IoLogoLinkedin, IoMdPhoneLandscape } from 'react-icons/io';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="box-footer1">
                    <a href="https://www.instagram.com/atitudetecnologia/">
                        <h2><IoLogoInstagram size={32} className="instagram-icon" />@atitudetecnologia</h2>
                    </a>
                    <a href="mailto:suporte.atitudetec@gmail.com">
                        <h2><IoIosMail size={32} className="email-icon" />suporte.atitudetec@gmail.com</h2>
                    </a>    
                    <a href="https://www.linkedin.com/in/atitude-tecnologia-08950929a/">
                        <h2><IoLogoLinkedin size={32} className="celular-icon" />Linkedin</h2>
                    </a>
                    <a href="tel:+5561981900035">
                        <h2><IoMdPhoneLandscape size={32} className="linkedin-icon" />55(61)9-8190-0035</h2>
                    </a>
                </div>
                <h2>&copy; 2023 Atitude Tecnologia. Todos os direitos reservados.</h2>
            </div>
        </footer>
    );
};

export default Footer;

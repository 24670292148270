// Atualização do componente CampoTexto
import React from 'react';
import './CampoTexto.css';

const CampoTexto = (props) => {
  const placeholderModificada = `${props.placeholder}...`;

  // Adicionando máscara para o número de telefone
  const formatarTelefone = (valor) => {
    // Implemente a lógica desejada para a máscara do telefone
    // Exemplo simples: (99) 99999-9999
    return valor.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  };

  const aoDigitado = (evento) => {
    let valorFormatado = evento.target.value;

    // Aplicando máscara baseado no nome do campo
    if (props.label === 'Telefone') {
      valorFormatado = formatarTelefone(valorFormatado);
    }

    props.aoAlterado(valorFormatado);
  };

  return (
    <div className="campo-texto">
      <label>{props.label}</label>
      <input
        value={props.valor}
        onChange={aoDigitado}
        required={props.obrigatorio}
        placeholder={placeholderModificada}
      />
    </div>
  );
};

export default CampoTexto;
import React, { useState, useRef, useEffect } from 'react';
import './Colaborador.css';
import { IoLogoWhatsapp } from 'react-icons/io';

const Colaborador = ({ nome, email, telefone, texto, nomeServico }) => {
  const [exibirConteudo, setExibirConteudo] = useState(false);
  const colaboradorRef = useRef(null);

  useEffect(() => {
    // Rolagem suave quando o componente é montado
    colaboradorRef.current.scrollIntoView({ behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (exibirConteudo && colaboradorRef.current) {
      colaboradorRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [exibirConteudo]);

  const handleClick = () => {
    setExibirConteudo(!exibirConteudo);
  };

  if (!texto) {
    return null;
  }

  return (
    <div className="headerContent" ref={colaboradorRef} onClick={handleClick} style={{ cursor: 'pointer' }}>
      {exibirConteudo ? (
        <div style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
          <h3>{nomeServico}</h3>
        </div>
      ) : (
        <>
          <div style={{ display: 'flex', justifyContent: 'center', color: 'white' }}>
            <h3>{nomeServico}</h3>
          </div>
          <div className="colaborador-text">
            <h4
              style={{
                fontWeight: 'normal',
                fontSize: '1.2rem',
                marginBottom: '20px',
                lineHeight: '1.6',
                color: 'white',
              }}
            >
              {texto}
            </h4>
          </div>
          <div className="cta-buttons">
            <a
              className="whatsapp-button"
              href="https://api.whatsapp.com/send?phone=61981900035"
              target="_blank"
              rel="noopener noreferrer"
            >
              <IoLogoWhatsapp className="whatsapp-icon" />
            </a>
          </div>
        </>
      )}
    </div>
  );
};

export default Colaborador;

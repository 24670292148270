import React, { useState } from 'react';
import Banner from './components/Banner';
import Formulario from './components/Formulario';
import ServicePreference from './components/ServicePreference'
import Footer from './components/Footer';
import SobreNos from './components/SobreNos';


const servicePreferences = [
  {
    nome: 'Desenvolvimento Web',
    corPrimaria: '#20065F',
    corSecundaria: '#20065F',
    texto: 'Desenvolvimento web é a chave para estabelecer uma forte presença online. Nossa equipe de especialistas cria sites elegantes e funcionais que cativam seu público-alvo. Seja para uma loja virtual, blog ou site corporativo, fornecemos soluções que impulsionam sua marca na era digital.'
  },
  {
    nome: 'Desenvolvimento Mobile',
    corPrimaria: '#120052',
    corSecundaria: '#120052',
    texto: 'Os dispositivos móveis dominam o mundo moderno. Desenvolvemos aplicativos móveis inovadores que conectam sua empresa diretamente aos bolsos de seus clientes. Deixe-nos transformar suas ideias em experiências móveis excepcionais.'
  },
  {
    nome: 'Data Science',
    corPrimaria: '#DA55B6',
    corSecundaria: '#DA55B6',
    texto: 'Dados são o novo petróleo. Nossa equipe de cientistas de dados desvenda insights valiosos que impulsionam sua tomada de decisões. Transforme seus dados brutos em vantagem competitiva com nossa experiência em Data Science.'
  },
  {
    nome: 'Consultoria Tecnológica',
    corPrimaria: '#000000',
    corSecundaria: '#000000',
    texto: 'A tecnologia é o motor do progresso. Oferecemos consultoria especializada para otimizar seus recursos tecnológicos, melhorar a eficiência e reduzir custos. Conte conosco para manter sua empresa na vanguarda da inovação.'
  },
  {
    nome: 'UX e Design',
    corPrimaria: '#20065F',
    corSecundaria: '#20065F',
    texto: 'Design excepcional é a chave para cativar seu público. Nossos especialistas em UX e design criam experiências memoráveis que destacam sua marca. Deixe-nos transformar ideias em designs impactantes.'
  },
  {
    nome: 'Copywriting',
    corPrimaria: '#120052',
    corSecundaria: '#120052',
    texto: 'Palavras têm poder. Nosso copywriting persuasivo e criativo conecta-se com seu público, transmitindo sua mensagem de forma envolvente. Deixe-nos contar sua história e transformar visitantes em clientes fiéis.'
  },
  {
    nome: 'Aulas de programação',
    corPrimaria: '#DA55B6',
    corSecundaria: '#DA55B6',
    texto: 'Aprenda a linguagem do futuro. Nossas aulas de programação capacitam você com as habilidades mais demandadas no mercado de tecnologia. Seja um mestre na criação de soluções inovadoras.'
  },
  {
    nome: 'Tráfego pago',
    corPrimaria: '#000000',
    corSecundaria: '#000000',
    texto: 'Atraia audiências qualificadas e aumente seu alcance online. Nossa estratégia de tráfego pago direciona visitantes relevantes para seu site, aumentando suas chances de conversão e crescimento.'
  },
  {
    nome: 'Consultoria de Inovação e Gestão',
    corPrimaria: '#20065F',
    corSecundaria: '#20065F',
    texto: 'Inovação é o motor do sucesso. Nossa consultoria de inovação e gestão ajuda a moldar estratégias de negócios inovadoras que impulsionam o crescimento e a eficiência. Prepare-se para liderar o caminho da transformação.'
  }
];

function App() {
  const [colaboradores, setColaboradores] = useState([]);

  const aoNovoColaboradorAdicionado = (colaborador) => {
    setColaboradores([...colaboradores, colaborador]);
  };

  return (
    <div className="App">
      <Banner />
      <Formulario
        aoColaboradorCadastrado={aoNovoColaboradorAdicionado}
        servicePreferences={servicePreferences} // Certifique-se de passar servicePreferences aqui
      />
      {servicePreferences.map((servicePreference) => (
        <ServicePreference
          key={servicePreference.nome}
          nome={servicePreference.nome}
          corPrimaria={servicePreference.corPrimaria}
          corSecundaria={servicePreference.corSecundaria}
          colaboradores={colaboradores.filter(
            (colaborador) => colaborador.servicePreference === servicePreference.nome
          )}
          servicePreferences={servicePreferences} // Certifique-se de passar servicePreferences aqui
        />
      ))}
      <SobreNos />
      <Footer />
    </div>
  );
}

export default App;




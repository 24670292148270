import React, { useState, useRef, useEffect} from 'react';
import Botao from '../Botao';
import CampoTexto from '../CampoTexto';
import ListaSuspensa from '../ListaSuspensa';
import './Formulario.css';

const Formulario = (props) => {
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [servicePreference, setServicePreference] = useState('');
  const [erro, setErro] = useState('');
  const [servicosSelecionados, setServicosSelecionados] = useState([]); // Array para rastrear os serviços selecionados
  const colaboradorRef = useRef(null);
  const [scrollToBottom, setScrollToBottom] = useState(false);

  useEffect(() => {
    if (scrollToBottom) {
      colaboradorRef.current.scrollIntoView({ behavior: 'smooth' });
      setScrollToBottom(false); // Redefine o estado para evitar rolar novamente desnecessariamente
    }
  }, [scrollToBottom]);

  const aoSalvar = (evento) => {
    evento.preventDefault();
  
    if (servicePreference) {
      if (servicosSelecionados.includes(servicePreference)) {
        setErro('Este serviço já foi selecionado.');
      } else {
        setServicosSelecionados((prevServicos) => [...prevServicos, servicePreference]);
        props.aoColaboradorCadastrado({
          nome,
          email,
          telefone,
          servicePreference,
        });
        setNome('');
        setEmail('');
        setTelefone('');
        setServicePreference('');
        setErro('');
        setScrollToBottom(true);
      }
    } else {
      setErro('Por favor, selecione um serviço de preferência.');
    }
  };

  const camposHabilitados = !servicePreference;

  return (
    <section className="formulario">
      <form onSubmit={aoSalvar}>
        <h2>Preencha os dados para solicitar mais informações</h2>
        <CampoTexto
          obrigatorio={camposHabilitados}
          label="Nome"
          placeholder="Digite seu nome"
          valor={nome}
          aoAlterado={(evento) => setNome(evento)}
          className="campo-cartao"
        />
        <CampoTexto
          obrigatorio={camposHabilitados}
          label="Email"
          placeholder="Digite seu email"
          valor={email}
          aoAlterado={(evento) => setEmail(evento)}
          className="campo-cartao"
        />
        <CampoTexto
          label="Telefone"
          placeholder="Digite o número do celular"
          valor={telefone}
          aoAlterado={(evento) => setTelefone(evento)}
          disabled={!camposHabilitados}
          className="campo-cartao"
        />
        <ListaSuspensa
          label="Nossos serviços"
          obrigatorio={camposHabilitados}
          placeholder="Selecione o serviço que tem interesse"
          itens={props.servicePreferences}
          valor={servicePreference}
          aoAlterado={(evento) => setServicePreference(evento)}
          className="campo-cartao"
        />
        <div className="mensagem-erro">{erro}</div>
        <div className="botao-centralizado">
          <Botao className="botao-servicos">Nossos Serviços</Botao>
        </div>
      </form>
      <div ref={colaboradorRef}></div>
    </section>
  );
};

export default Formulario;

import React from 'react';
import './ServicePreference.css';
import Colaborador from '../Colaborador';

const ServicePreference = (props) => {
  const sectionStyle = { backgroundColor: props.corSecundaria };
  return (
    <section className="service-preference" style={sectionStyle}>
     {props.texto && (<div style={{display:'flex',justifyContent: 'center'}}> <h3>{props.nome}</h3></div>)} 
      <div className="colaboradores">
      {props.colaboradores.map((colaborador, index) => (
  <Colaborador
    style={{ backgroundColor: props.corPrimaria }}
    key={index}
    nome={colaborador.nome}
    email={colaborador.email}
    telefone={colaborador.telefone}
    texto={
      typeof props.servicePreferences.find(
        (service) => service.nome === colaborador.servicePreference
      )?.texto === 'string'
        ? props.servicePreferences.find(
            (service) => service.nome === colaborador.servicePreference
          )?.texto
        : 'Texto não definido'
    }
    nomeServico={colaborador.servicePreference} // Passa o nome do serviço selecionado
  />
))}
      </div>
    </section>
  );
};

export default ServicePreference;

import React from 'react';
import './SobreNos.css';
const SobreNos = () => {
  return (
    <section className="sobre-nos">
      <div className="sobre-nos-container">
        <div className="sobre-nos-conteudo">
          <h2 className="sobre-nos-titulo">Quem Somos</h2>
          <p className="sobre-nos-descricao">
            Somos uma equipe apaixonada por inovação e tecnologia. Nossa missão é
            fornecer soluções excepcionais que impulsionam o seu sucesso no mundo
            digital.
          </p>
          <h4 className="sobre-nos-descricao">
            Com uma abordagem criativa e orientada por resultados, estamos
            comprometidos em fornecer as melhores estratégias e serviços para
            atender às suas necessidades.
          </h4>
          <h4 className="sobre-nos-descricao">
            Acreditamos que o seu sucesso é o nosso sucesso.
          </h4>
        </div>
      </div>
    </section>
  );
};

export default SobreNos;

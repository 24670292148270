// Banner.js

import React from 'react';
import './Banner.css';

export const Banner = () => {
  return (
    <header className='banner'>
        <div className="logo-img-div">
        <img src="/images/logo.jpg" alt="logo" />
        </div>
        
      <div className="content-wrapper">
        <div className="text-block">
          <h1>Potencialize o seu sucesso digital com a Atitude Tecnologia</h1>
          <p>Soluções digitais personalizadas para impulsionar o seu negócio.</p>
        </div>
        <div className="text-block">
          <h1 style={{ color: '#DA55B6' }}>Desenvolvendo Websites, Apps e Estratégias para o seu crescimento Online.</h1>
          <p>Transformamos ideias em experiências digitais impactantes.</p>
        </div>
      </div>
    </header>
  );
}
